/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from 'react';
import PropTypes from 'prop-types';
import 'typeface-josefin-sans';

import './src/css/tailwind.css';

function wrapRootElement({ element }) {
  return <>{element}</>;
}

wrapRootElement.propTypes = {
  element: PropTypes.node.isRequired,
};

export { wrapRootElement };
